import React from 'react'
import { Link } from 'gatsby'
import { translate } from "react-i18next"

import Layout from '../components/layouts/baseLayout'
import Form from '../components/contact_form/form'
import Navbar from '../components/navbar/navbar'

import allStyle from "../styles/all.module.scss"


import uliege from "../images/index/uliege_logo_gris.png"
import liege from "../images/index/liege.png"
import chu from "../images/index/chu.png"
import cpas from "../images/index/cpas.png"
import helmo from "../images/index/helmo.png"

import indexStyle from '../styles/index.module.scss'
import three from "../images/index/step_3/three.png"
import computer from "../images/computer.svg"
import smartphone from "../images/smartphone.svg"
import gears from "../images/gears.svg"



function Join (props) {
  const { t } = props;
  return (
    <Layout>

      <main className={allStyle.pro}>
        <div className={allStyle.join_page}>
          <div className={allStyle.join}>
            <div className={allStyle.wrap}>
              <div className={allStyle.intro}>
                <h3>{t("join_title")}<br/>{t("join_title_two")}</h3>
                <p>{t("join_text")}</p>
                <a className={allStyle.custom_button} href="#contact">{t("contact_us")}</a>
                <p className={allStyle.text}>{t("already_member")}<a className={indexStyle.link} href="/app/#auth/login">{t("connexion")}</a></p>
              </div>
              <div className={allStyle.join_visu}>
                <div className={allStyle.join_image}>
                  <img className={allStyle.picture} src={three} alt="site web"/>
                </div>
                <div className={allStyle.partenaires}>
                  <div className={allStyle.logo}>
                    <img src={uliege} alt="logo de la ville de liège"/>
                    <img src={chu} alt="logo du CHU"/>
                    <img src={liege} alt="logo de la ville de liège"/>
                    <img src={cpas} alt="logo du cpas"/>
                    <img src={helmo} alt="logo de l'Helmo"/>
                  </div>
                  <p>{t("partners")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={allStyle.solution}>
            <h3>{t("ugo_solution")}</h3>
            <div className={allStyle.argument}>
              <div className={allStyle.picto}>
                <img src={smartphone} alt="smartphone"/>
                <h5>{t("arg_title_one")}</h5>
                <p>{t("arg_text_one")}</p>
              </div>
              <div className={allStyle.picto}>
                <img src={computer} alt="ordinateur"/>
                <h5>{t("arg_title_two")}</h5>
                <p>{t("arg_text_two")}</p>
              </div>
              <div className={allStyle.picto}>
                <img src={gears} alt="engrenages"/>
                <h5>{t("arg_title_three")}</h5>
                <p>{t("arg_text_three")}</p>
              </div>
            </div>
            <Link className={allStyle.about} to="/about">{t("about")}</Link>
          </div>
          <div id="contact" className={allStyle.contact}>
            <div className={allStyle.form}>
              <h3>{t("want_join")}</h3>
              <p>{t("want_join_text")}</p>
              <h3>{t("contact_us")}</h3>
              <Form/>
            </div>
          </div>



        </div>
      </main>
    </Layout>)
}


export default translate("all")(Join)